::selection {
  color: white;
  background: #692ee5;
}

select {
  -webkit-appearance: menulist-button;
}

.button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: #692ee5;
  border-radius: 100px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: white;
  padding: 12px 48px;

  transition: 0.2s ease-out;
}

.button:hover {
  background: #461aa6;
  box-shadow: 0 0 10px #5c26d06e;
}

.header {
  border-radius: 0 0 15px 15px;
}

.mont {
  font-family: 'Mont', sans-serif;
}

.light-button {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 100px;

  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #692ee5;
  padding: 10px 46px;

  transition: 0.2s ease-out;

  border: 1px solid #692ee5;
}

.light-button:hover {
  color: white;
  background: #461aa6;
  box-shadow: 0 0 10px #5c26d06e;
  border-color: white;
}

.hero {
  border-radius: 15px;
  color: white;
  background: #692ee5 url('../images/hero.jpg') no-repeat center/cover;
}

.rating {
  border-radius: 15px;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
}

.calculation {
  border-radius: 15px;
  overflow: hidden;
}

.selector {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #f4f4f4;
  height: 52px;
  border-radius: 5px;
  padding-inline: 10px;
}

.selector-elem {
  display: flex;
  height: fit-content;
  padding: 10px;
  border-radius: 5px;
  transition: .2s background-color ease-out;
}

.selector-elem.is-active {
  background: #692ee5;
  font-weight: 700;
  color: white;
}

.range {
  max-width: 425px;
  cursor: grab;
  width: 100%;
  margin-bottom: 2px;
  appearance: none;
  background: #eaeaea;
  height: 6px;
  border-radius: 30px;
}

.range:active {
  cursor: grabbing;
}

.range-thumb {
  pointer-events: none;
}

.footer {
  border-radius: 15px 15px 0 0;
}

.row {
  flex-shrink: 0;
}

.modals {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fffff560;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  opacity: 0;
  pointer-events: none;
  z-index: 20;
  transition: 0.5s ease-out all;
}

.modals.is-visible {
  pointer-events: auto;
  opacity: 1;
}

.modals .modal {
  display: none;
  cursor: default;
}

.modals .modal.is-visible {
  display: flex;
}

label {
  cursor: pointer;
}

input[type='checkbox'] {
  appearance: none;
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 33%;
  padding: 5px;
  background: white;
  border: 1.5px solid #000;
}

input[type='checkbox']::after {
  position: absolute;
  content: '';
  display: block;
  border-radius: 33%;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  transition: 0.2s ease-out all;
}

.rating {
  transition: 2s ease all;
}

.rating-overlay {
  cursor: pointer;
  justify-content: center;
  padding-block: 20px;
  padding-inline: 10px;
  background: linear-gradient(
    180deg,
    #ffffff -30%,
    rgba(255, 255, 255, 0) -29.99%,
    #ffffff 100%
  );
  filter: drop-shadow(0px 4px 8px rgba(171, 171, 171, 0.07));
  border-radius: 15px;
  z-index: 2;
  position: absolute;
  display: flex;
  align-items: end;
  height: 8%;
  bottom: 0;
  left: 0;
  right: 0;
}

.rating-overlay p {
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;

  text-decoration-line: underline;

  color: #692ee5;
}

input[type='checkbox']:checked:after {
  background: #692ee5;
}

@media (max-width: 767px) {
  .header-second {
    position: absolute;
    transition: 0.2s ease-out all;
    right: 100%;
    top: 110px;
    background: white;
  }

  .header-second.is-visible {
    right: 0;
  }

  .vertical-text {
    writing-mode: horizontal-tb;
    transform: none;
  }

  .header-first {
    transition: 0.3s ease-out all;
  }

  .header-first.is-visible {
    max-height: 120px;
  }

  .header.is-visible {
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .button,
  .light-button {
    font-size: 15px;
    line-height: 18px;
  }

  .rating-overlay p {
    font-size: 16px;
    line-height: 22px;
  }
}
