@font-face {
  font-family: Arial;
  src: url("Arial-ItalicMT.b8f97197.eot");
  src: local(Arial Italic), local(Arial-ItalicMT), url("Arial-ItalicMT.b8f97197.eot#iefix") format("embedded-opentype"), url("Arial-ItalicMT.9416e855.woff2") format("woff2"), url("Arial-ItalicMT.81821a4e.woff") format("woff"), url("Arial-ItalicMT.dc36f09c.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Arial;
  src: url("ArialMT.4d372635.eot");
  src: local(Arial), local(ArialMT), url("ArialMT.4d372635.eot#iefix") format("embedded-opentype"), url("ArialMT.d1d88784.woff2") format("woff2"), url("ArialMT.78b7b9ab.woff") format("woff"), url("ArialMT.ee893f2e.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Arial;
  src: url("Arial-BoldItalicMT.2bd8aac9.eot");
  src: local(Arial Bold Italic), local(Arial-BoldItalicMT), url("Arial-BoldItalicMT.2bd8aac9.eot#iefix") format("embedded-opentype"), url("Arial-BoldItalicMT.26cdb931.woff2") format("woff2"), url("Arial-BoldItalicMT.b9ec1537.woff") format("woff"), url("Arial-BoldItalicMT.f2ec46cc.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Arial Narrow;
  src: url("ArialNarrow-BoldItalic.fc27a39c.eot");
  src: local(Arial Narrow Bold Italic), local(ArialNarrow-BoldItalic), url("ArialNarrow-BoldItalic.fc27a39c.eot#iefix") format("embedded-opentype"), url("ArialNarrow-BoldItalic.37d5518b.woff2") format("woff2"), url("ArialNarrow-BoldItalic.710685b4.woff") format("woff"), url("ArialNarrow-BoldItalic.8e5d210d.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Arial Narrow;
  src: url("ArialNarrow-Bold.56956b47.eot");
  src: local(Arial Narrow Bold), local(ArialNarrow-Bold), url("ArialNarrow-Bold.56956b47.eot#iefix") format("embedded-opentype"), url("ArialNarrow-Bold.c187812d.woff2") format("woff2"), url("ArialNarrow-Bold.be21b6c7.woff") format("woff"), url("ArialNarrow-Bold.4fab14e9.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Arial Narrow;
  src: url("ArialNarrow-Italic.0ed54651.eot");
  src: local(Arial Narrow Italic), local(ArialNarrow-Italic), url("ArialNarrow-Italic.0ed54651.eot#iefix") format("embedded-opentype"), url("ArialNarrow-Italic.5df61ae5.woff2") format("woff2"), url("ArialNarrow-Italic.64968f99.woff") format("woff"), url("ArialNarrow-Italic.5f2a59bf.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Arial Narrow;
  src: url("ArialNarrow.7fd55cc7.eot");
  src: local(Arial Narrow), local(ArialNarrow), url("ArialNarrow.7fd55cc7.eot#iefix") format("embedded-opentype"), url("ArialNarrow.b3b81635.woff2") format("woff2"), url("ArialNarrow.81cdba92.woff") format("woff"), url("ArialNarrow.0aee0e60.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Arial;
  src: url("Arial-BoldMT.9ace78fe.eot");
  src: local(Arial Bold), local(Arial-BoldMT), url("Arial-BoldMT.9ace78fe.eot#iefix") format("embedded-opentype"), url("Arial-BoldMT.6b894aec.woff2") format("woff2"), url("Arial-BoldMT.4cb2ac4d.woff") format("woff"), url("Arial-BoldMT.697212bc.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Black.1364a9b6.eot");
  src: local(Mont Black), local(Mont-Black), url("Mont-Black.1364a9b6.eot#iefix") format("embedded-opentype"), url("Mont-Black.6f7eb178.woff2") format("woff2"), url("Mont-Black.c0502c5b.woff") format("woff"), url("Mont-Black.e6add188.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-ThinItalic.9adccea8.eot");
  src: local(Mont Thin Italic), local(Mont-ThinItalic), url("Mont-ThinItalic.9adccea8.eot#iefix") format("embedded-opentype"), url("Mont-ThinItalic.3c76087c.woff2") format("woff2"), url("Mont-ThinItalic.a84e4f4b.woff") format("woff"), url("Mont-ThinItalic.04ed3ee2.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-ExtraLight.b0d13489.eot");
  src: local(Mont ExtraLight), local(Mont-ExtraLight), url("Mont-ExtraLight.b0d13489.eot#iefix") format("embedded-opentype"), url("Mont-ExtraLight.0a3e38fd.woff2") format("woff2"), url("Mont-ExtraLight.cfb03c66.woff") format("woff"), url("Mont-ExtraLight.bb630ce5.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Bold.7080ea5f.eot");
  src: local(Mont Bold), local(Mont-Bold), url("Mont-Bold.7080ea5f.eot#iefix") format("embedded-opentype"), url("Mont-Bold.079e6e11.woff2") format("woff2"), url("Mont-Bold.ab4d4e6a.woff") format("woff"), url("Mont-Bold.2c75e4d5.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-HeavyItalic.4a670cf0.eot");
  src: local(Mont Heavy Italic), local(Mont-HeavyItalic), url("Mont-HeavyItalic.4a670cf0.eot#iefix") format("embedded-opentype"), url("Mont-HeavyItalic.72f16c36.woff2") format("woff2"), url("Mont-HeavyItalic.f56ccec1.woff") format("woff"), url("Mont-HeavyItalic.1a8547ac.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-BlackItalic.b08eaf39.eot");
  src: local(Mont Black Italic), local(Mont-BlackItalic), url("Mont-BlackItalic.b08eaf39.eot#iefix") format("embedded-opentype"), url("Mont-BlackItalic.37a199ca.woff2") format("woff2"), url("Mont-BlackItalic.49dc5e71.woff") format("woff"), url("Mont-BlackItalic.7cac2cf6.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-ExtraLightItalic.ad7d1ed2.eot");
  src: local(Mont ExtraLight Italic), local(Mont-ExtraLightItalic), url("Mont-ExtraLightItalic.ad7d1ed2.eot#iefix") format("embedded-opentype"), url("Mont-ExtraLightItalic.91eb3583.woff2") format("woff2"), url("Mont-ExtraLightItalic.883693fc.woff") format("woff"), url("Mont-ExtraLightItalic.76d76e54.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Heavy.e5946d1e.eot");
  src: local(Mont Heavy), local(Mont-Heavy), url("Mont-Heavy.e5946d1e.eot#iefix") format("embedded-opentype"), url("Mont-Heavy.f781317f.woff2") format("woff2"), url("Mont-Heavy.41451ca2.woff") format("woff"), url("Mont-Heavy.ee2afd7a.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-SemiBold.794902f2.eot");
  src: local(Mont SemiBold), local(Mont-SemiBold), url("Mont-SemiBold.794902f2.eot#iefix") format("embedded-opentype"), url("Mont-SemiBold.b4b41305.woff2") format("woff2"), url("Mont-SemiBold.5c6ae696.woff") format("woff"), url("Mont-SemiBold.a9fb0988.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-RegularItalic.b1512dad.eot");
  src: local(Mont Regular Italic), local(Mont-RegularItalic), url("Mont-RegularItalic.b1512dad.eot#iefix") format("embedded-opentype"), url("Mont-RegularItalic.af30cb7d.woff2") format("woff2"), url("Mont-RegularItalic.4008fff6.woff") format("woff"), url("Mont-RegularItalic.55d799f6.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-BoldItalic.e78bf57f.eot");
  src: local(Mont Bold Italic), local(Mont-BoldItalic), url("Mont-BoldItalic.e78bf57f.eot#iefix") format("embedded-opentype"), url("Mont-BoldItalic.db537b21.woff2") format("woff2"), url("Mont-BoldItalic.4ae55dbe.woff") format("woff"), url("Mont-BoldItalic.d16e7440.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-SemiBoldItalic.7af400e4.eot");
  src: local(Mont SemiBold Italic), local(Mont-SemiBoldItalic), url("Mont-SemiBoldItalic.7af400e4.eot#iefix") format("embedded-opentype"), url("Mont-SemiBoldItalic.03e95484.woff2") format("woff2"), url("Mont-SemiBoldItalic.acc0ccf6.woff") format("woff"), url("Mont-SemiBoldItalic.b1484e81.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-LightItalic.d6472f55.eot");
  src: local(Mont Light Italic), local(Mont-LightItalic), url("Mont-LightItalic.d6472f55.eot#iefix") format("embedded-opentype"), url("Mont-LightItalic.c28a0ed9.woff2") format("woff2"), url("Mont-LightItalic.caa5ef42.woff") format("woff"), url("Mont-LightItalic.3fb5de39.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Thin.27df880f.eot");
  src: local(Mont Thin), local(Mont-Thin), url("Mont-Thin.27df880f.eot#iefix") format("embedded-opentype"), url("Mont-Thin.1b8bce2d.woff2") format("woff2"), url("Mont-Thin.3e09768b.woff") format("woff"), url("Mont-Thin.ff269cb8.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Regular.f51c8c5c.eot");
  src: local(Mont Regular), local(Mont-Regular), url("Mont-Regular.f51c8c5c.eot#iefix") format("embedded-opentype"), url("Mont-Regular.842fba01.woff2") format("woff2"), url("Mont-Regular.bb2562d9.woff") format("woff"), url("Mont-Regular.b75f73be.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Mont;
  src: url("Mont-Light.02104bfc.eot");
  src: local(Mont Light), local(Mont-Light), url("Mont-Light.02104bfc.eot#iefix") format("embedded-opentype"), url("Mont-Light.724ee76e.woff2") format("woff2"), url("Mont-Light.724a7293.woff") format("woff"), url("Mont-Light.b710cfba.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

::selection {
  color: #fff;
  background: #692ee5;
}

select {
  -webkit-appearance: menulist-button;
}

.button {
  color: #fff;
  background: #692ee5;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 12px 48px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  transition: all .2s ease-out;
  display: flex;
}

.button:hover {
  background: #461aa6;
  box-shadow: 0 0 10px #5c26d06e;
}

.header {
  border-radius: 0 0 15px 15px;
}

.mont {
  font-family: Mont, sans-serif;
}

.light-button {
  color: #692ee5;
  background: #fff;
  border: 1px solid #692ee5;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 46px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  transition: all .2s ease-out;
  display: flex;
}

.light-button:hover {
  color: #fff;
  background: #461aa6;
  border-color: #fff;
  box-shadow: 0 0 10px #5c26d06e;
}

.hero {
  color: #fff;
  background: #692ee5 url("hero.c39e0677.jpg") center / cover no-repeat;
  border-radius: 15px;
}

.rating {
  border-radius: 15px;
}

.vertical-text {
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
}

.calculation {
  border-radius: 15px;
  overflow: hidden;
}

.selector {
  height: 52px;
  background: #f4f4f4;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  padding-inline: 10px;
  display: flex;
}

.selector-elem {
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  padding: 10px;
  transition: background-color .2s ease-out;
  display: flex;
}

.selector-elem.is-active {
  color: #fff;
  background: #692ee5;
  font-weight: 700;
}

.range {
  max-width: 425px;
  cursor: grab;
  width: 100%;
  appearance: none;
  height: 6px;
  background: #eaeaea;
  border-radius: 30px;
  margin-bottom: 2px;
}

.range:active {
  cursor: grabbing;
}

.range-thumb {
  pointer-events: none;
}

.footer {
  border-radius: 15px 15px 0 0;
}

.row {
  flex-shrink: 0;
}

.modals {
  cursor: pointer;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  opacity: 0;
  pointer-events: none;
  z-index: 20;
  background: #fffff560;
  justify-content: center;
  align-items: center;
  gap: 40px;
  transition: all .5s ease-out;
  display: flex;
  position: fixed;
  inset: 0;
}

.modals.is-visible {
  pointer-events: auto;
  opacity: 1;
}

.modals .modal {
  cursor: default;
  display: none;
}

.modals .modal.is-visible {
  display: flex;
}

label {
  cursor: pointer;
}

input[type="checkbox"] {
  appearance: none;
  width: 25px;
  height: 25px;
  background: #fff;
  border: 1.5px solid #000;
  border-radius: 33%;
  padding: 5px;
  position: relative;
}

input[type="checkbox"]:after {
  content: "";
  border-radius: 33%;
  transition: all .2s ease-out;
  display: block;
  position: absolute;
  inset: 5px;
}

.rating {
  transition: all 2s;
}

.rating-overlay {
  cursor: pointer;
  filter: drop-shadow(0 4px 8px #ababab12);
  z-index: 2;
  height: 8%;
  background: linear-gradient(#fff -30%, #fff0 -29.99%, #fff 100%);
  border-radius: 15px;
  justify-content: center;
  align-items: end;
  padding-block: 20px;
  padding-inline: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.rating-overlay p {
  color: #692ee5;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  text-decoration-line: underline;
}

input[type="checkbox"]:checked:after {
  background: #692ee5;
}

@media (max-width: 767px) {
  .header-second {
    background: #fff;
    transition: all .2s ease-out;
    position: absolute;
    top: 110px;
    right: 100%;
  }

  .header-second.is-visible {
    right: 0;
  }

  .vertical-text {
    writing-mode: horizontal-tb;
    transform: none;
  }

  .header-first {
    transition: all .3s ease-out;
  }

  .header-first.is-visible {
    max-height: 120px;
  }

  .header.is-visible {
    border-radius: 0;
  }
}

@media (max-width: 575px) {
  .button, .light-button {
    font-size: 15px;
    line-height: 18px;
  }

  .rating-overlay p {
    font-size: 16px;
    line-height: 22px;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

.collapse {
  visibility: collapse;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.left-0 {
  left: 0;
}

.right-\[-6px\] {
  right: -6px;
}

.right-\[10px\] {
  right: 10px;
}

.right-\[60px\] {
  right: 60px;
}

.top-0 {
  top: 0;
}

.top-\[10px\] {
  top: 10px;
}

.z-10 {
  z-index: 10;
}

.mx-\[-40px\] {
  margin-left: -40px;
  margin-right: -40px;
}

.mx-\[15px\] {
  margin-left: 15px;
  margin-right: 15px;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-\[-15px\] {
  margin-top: -15px;
  margin-bottom: -15px;
}

.mb-\[10px\] {
  margin-bottom: 10px;
}

.mb-\[13px\] {
  margin-bottom: 13px;
}

.mb-\[15px\] {
  margin-bottom: 15px;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[28px\] {
  margin-bottom: 28px;
}

.mb-\[30px\] {
  margin-bottom: 30px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.mb-\[5px\] {
  margin-bottom: 5px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.ml-\[-50px\] {
  margin-left: -50px;
}

.ml-\[238px\] {
  margin-left: 238px;
}

.ml-\[246px\] {
  margin-left: 246px;
}

.ml-\[50px\] {
  margin-left: 50px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.h-\[10px\] {
  height: 10px;
}

.h-\[115px\] {
  height: 115px;
}

.h-\[125px\] {
  height: 125px;
}

.h-\[160px\] {
  height: 160px;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[52px\] {
  height: 52px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[6px\] {
  height: 6px;
}

.h-\[80px\] {
  height: 80px;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.min-h-\[670px\] {
  min-height: 670px;
}

.min-h-\[80px\] {
  min-height: 80px;
}

.w-\[10px\] {
  width: 10px;
}

.w-\[115px\] {
  width: 115px;
}

.w-\[228px\] {
  width: 228px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[276px\] {
  width: 276px;
}

.w-\[290px\] {
  width: 290px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[30px\] {
  width: 30px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[355px\] {
  width: 355px;
}

.w-\[425px\] {
  width: 425px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[700px\] {
  width: 700px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[80px\] {
  width: 80px;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.max-w-\[100\%\] {
  max-width: 100%;
}

.max-w-\[1160px\] {
  max-width: 1160px;
}

.max-w-\[12\.5\%\] {
  max-width: 12.5%;
}

.max-w-\[15\%\] {
  max-width: 15%;
}

.max-w-\[2\.5\%\] {
  max-width: 2.5%;
}

.max-w-\[20\%\] {
  max-width: 20%;
}

.max-w-\[24\%\] {
  max-width: 24%;
}

.max-w-\[26\%\] {
  max-width: 26%;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[280px\] {
  max-width: 280px;
}

.max-w-\[320px\] {
  max-width: 320px;
}

.max-w-\[324px\] {
  max-width: 324px;
}

.max-w-\[420px\] {
  max-width: 420px;
}

.max-w-\[425px\] {
  max-width: 425px;
}

.max-w-\[430px\] {
  max-width: 430px;
}

.max-w-\[445px\] {
  max-width: 445px;
}

.max-w-\[465px\] {
  max-width: 465px;
}

.max-w-\[50\%\] {
  max-width: 50%;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.border-collapse {
  border-collapse: collapse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-\[10px\] {
  gap: 10px;
}

.gap-\[15px\] {
  gap: 15px;
}

.gap-\[16px\] {
  gap: 16px;
}

.gap-\[18px\] {
  gap: 18px;
}

.gap-\[20px\] {
  gap: 20px;
}

.gap-\[2px\] {
  gap: 2px;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-\[5px\] {
  gap: 5px;
}

.gap-\[80px\] {
  gap: 80px;
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[15px\] {
  border-radius: 15px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-\[\#692EE5\] {
  --tw-border-opacity: 1;
  border-color: rgb(105 46 229 / var(--tw-border-opacity));
}

.border-\[\#EBEBEB\] {
  --tw-border-opacity: 1;
  border-color: rgb(235 235 235 / var(--tw-border-opacity));
}

.bg-\[\#692EE5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(105 46 229 / var(--tw-bg-opacity));
}

.bg-\[\#EDEEFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(237 238 255 / var(--tw-bg-opacity));
}

.bg-\[\#F6F6F6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
}

.bg-\[\#ebebeb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 235 235 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.p-10 {
  padding: 2.5rem;
}

.p-3 {
  padding: .75rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[15px\] {
  padding: 15px;
}

.p-\[16px\] {
  padding: 16px;
}

.p-\[20px\] {
  padding: 20px;
}

.p-\[28px\] {
  padding: 28px;
}

.p-\[30px\] {
  padding: 30px;
}

.p-\[40px\] {
  padding: 40px;
}

.p-\[50px\] {
  padding: 50px;
}

.p-\[5px\] {
  padding: 5px;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.px-\[50px\] {
  padding-left: 50px;
  padding-right: 50px;
}

.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.py-\[22px\] {
  padding-top: 22px;
  padding-bottom: 22px;
}

.pb-\[35px\] {
  padding-bottom: 35px;
}

.pb-\[40px\] {
  padding-bottom: 40px;
}

.pt-\[40px\] {
  padding-top: 40px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-\[27px\] {
  font-size: 27px;
}

.text-\[34px\] {
  font-size: 34px;
}

.text-\[35px\] {
  font-size: 35px;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.leading-\[16px\] {
  line-height: 16px;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-\[19px\] {
  line-height: 19px;
}

.leading-\[20px\] {
  line-height: 20px;
}

.leading-\[21px\] {
  line-height: 21px;
}

.leading-\[22px\] {
  line-height: 22px;
}

.leading-\[24px\] {
  line-height: 24px;
}

.leading-\[25px\] {
  line-height: 25px;
}

.leading-\[28px\] {
  line-height: 28px;
}

.leading-\[29px\] {
  line-height: 29px;
}

.leading-\[32px\] {
  line-height: 32px;
}

.leading-\[40px\] {
  line-height: 40px;
}

.leading-\[44px\] {
  line-height: 44px;
}

.text-\[\#26B00A\] {
  --tw-text-opacity: 1;
  color: rgb(38 176 10 / var(--tw-text-opacity));
}

.text-\[\#2D2D2D\], .text-\[\#2d2d2d\] {
  --tw-text-opacity: 1;
  color: rgb(45 45 45 / var(--tw-text-opacity));
}

.text-\[\#C62E2E\] {
  --tw-text-opacity: 1;
  color: rgb(198 46 46 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

:root {
  scroll-behavior: smooth;
  scroll-padding-top: 50px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.hover\:bg-\[\#d8dafa\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(216 218 250 / var(--tw-bg-opacity));
}

.hover\:text-\[\#461aa6\]:hover {
  --tw-text-opacity: 1;
  color: rgb(70 26 166 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (max-width: 1279px) {
  .xl\:absolute {
    position: absolute;
  }

  .xl\:bottom-\[-5px\] {
    bottom: -5px;
  }

  .xl\:left-\[36px\] {
    left: 36px;
  }

  .xl\:right-\[10px\] {
    right: 10px;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 1023px) {
  .lg\:order-8 {
    order: 8;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:ml-\[20px\] {
    margin-left: 20px;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-\[100\%\] {
    max-width: 100%;
  }

  .lg\:max-w-\[30\%\] {
    max-width: 30%;
  }

  .lg\:max-w-\[32\%\] {
    max-width: 32%;
  }

  .lg\:max-w-\[40px\] {
    max-width: 40px;
  }

  .lg\:max-w-\[630px\] {
    max-width: 630px;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:gap-\[10px\] {
    gap: 10px;
  }

  .lg\:pr-\[10px\] {
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:bottom-\[15px\] {
    bottom: 15px;
  }

  .md\:left-\[10px\] {
    left: 10px;
  }

  .md\:left-\[120px\] {
    left: 120px;
  }

  .md\:right-\[10px\] {
    right: 10px;
  }

  .md\:top-\[10px\] {
    top: 10px;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-\[10px\] {
    margin: 10px;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mr-\[28px\] {
    margin-right: 28px;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[20px\] {
    height: 20px;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:max-h-\[50px\] {
    max-height: 50px;
  }

  .md\:w-\[20px\] {
    width: 20px;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-\[20px\] {
    max-width: 20px;
  }

  .md\:max-w-\[30\%\] {
    max-width: 30%;
  }

  .md\:max-w-\[60px\] {
    max-width: 60px;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:text-\[14px\] {
    font-size: 14px;
  }

  .md\:text-\[16px\] {
    font-size: 16px;
  }

  .md\:font-bold {
    font-weight: 700;
  }

  .md\:leading-\[18px\] {
    line-height: 18px;
  }

  .md\:text-\[\#692EE5\] {
    --tw-text-opacity: 1;
    color: rgb(105 46 229 / var(--tw-text-opacity));
  }

  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (max-width: 575px) {
  .sm\:absolute {
    position: absolute;
  }

  .sm\:top-\[45px\] {
    top: 45px;
  }

  .sm\:m-\[10px\] {
    margin: 10px;
  }

  .sm\:m-\[20px\] {
    margin: 20px;
  }

  .sm\:mb-\[10px\] {
    margin-bottom: 10px;
  }

  .sm\:mb-\[15px\] {
    margin-bottom: 15px;
  }

  .sm\:mb-\[20px\] {
    margin-bottom: 20px;
  }

  .sm\:mb-\[5px\] {
    margin-bottom: 5px;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-\[110px\] {
    margin-left: 110px;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-\[15px\] {
    height: 15px;
  }

  .sm\:h-\[18px\] {
    height: 18px;
  }

  .sm\:h-\[80px\] {
    height: 80px;
  }

  .sm\:h-\[90px\] {
    height: 90px;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:min-h-fit {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }

  .sm\:w-\[15px\] {
    width: 15px;
  }

  .sm\:w-\[175px\] {
    width: 175px;
  }

  .sm\:w-\[35px\] {
    width: 35px;
  }

  .sm\:w-\[70px\] {
    width: 70px;
  }

  .sm\:w-\[80px\] {
    width: 80px;
  }

  .sm\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-\[240px\] {
    max-width: 240px;
  }

  .sm\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:gap-\[10px\] {
    gap: 10px;
  }

  .sm\:gap-\[15px\] {
    gap: 15px;
  }

  .sm\:gap-\[20px\] {
    gap: 20px;
  }

  .sm\:gap-y-0 {
    row-gap: 0;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:p-\[15px\] {
    padding: 15px;
  }

  .sm\:p-\[6px\] {
    padding: 6px;
  }

  .sm\:px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .sm\:py-\[15px\] {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .sm\:py-\[20px\] {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .sm\:pt-\[10px\] {
    padding-top: 10px;
  }

  .sm\:pt-\[30px\] {
    padding-top: 30px;
  }

  .sm\:text-\[12px\] {
    font-size: 12px;
  }

  .sm\:text-\[14px\] {
    font-size: 14px;
  }

  .sm\:text-\[16px\] {
    font-size: 16px;
  }

  .sm\:text-\[18px\] {
    font-size: 18px;
  }

  .sm\:text-\[19px\] {
    font-size: 19px;
  }

  .sm\:text-\[22px\] {
    font-size: 22px;
  }

  .sm\:leading-\[16px\] {
    line-height: 16px;
  }

  .sm\:leading-\[18px\] {
    line-height: 18px;
  }

  .sm\:leading-\[19px\] {
    line-height: 19px;
  }

  .sm\:leading-\[21px\] {
    line-height: 21px;
  }

  .sm\:leading-\[26px\] {
    line-height: 26px;
  }

  .sm\:leading-\[28px\] {
    line-height: 28px;
  }

  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

/*# sourceMappingURL=index.3363109e.css.map */
