@import url('./fonts.css');
@import url('./my-styles.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  scroll-padding-top: 50px;

  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 18px;

  scroll-behavior: smooth;
}
